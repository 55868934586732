/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.mat-card-header-text {
  margin: 0 0px !important;
}

mat-card {

  margin-left: 5px;
}

.mat-form-field .mat-input-element {
  font-size: 18px;
}
.mat-form-field .mat-form-field-label {
  font-size: 16px;
}

.mat-select-value {
  font-size: 18px;
}
.mat-option-text {
  font-size: 16px;
}

.mat-checkbox-label {
  font-size: 18px;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin-top: 3px !important;
  height: 18px !important;
  width: 18px !important;
}

.mat-raised-button {
  font-size: 16px;
}

.mat-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width:330px !important;
}

h2 {
  margin: 0px;
}

body {
  background-image: url('assets/img/pageBG.jpg');
  background-position: top left;
  background-repeat: repeat-x repeat-y;
}

.all-wrap {
  min-height: 98%;
}

.page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 98%;
}

.content {
  flex: 1;
}

main {
  display: flex;
  flex-direction: column;
}

a.forget-passwordlink:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
