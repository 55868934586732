/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.mat-card-header-text {
  margin: 0 0px !important;
}

button.mat-menu-item {
  color: white;
}

mat-nav-list.mat-nav-list {
  background-color: #c5eefa !important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.mat-drawer-inner-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.mat-drawer-inner-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mat-form-field .mat-input-element {
  font-size: 18px;
}
.mat-form-field .mat-form-field-label {
  font-size: 16px;
}

.mat-select-value {
  font-size: 18px;
}
.mat-option-text {
  font-size: 16px;
}

.mat-checkbox-label {
  font-size: 18px;
}

.mat-checkbox-inner-container {
  height: 18px !important;
  width: 18px !important;
}

.mat-checkbox.cdk-keyboard-focused {
  background-color: rgb(126, 218, 241);
}

/*.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black !important;
}*/

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5 !important;
}

.mat-raised-button {
  font-size: 16px;
  background-color: #00B2E3;
  color:black;
  width:auto;
  border-radius: 8px;
  height:auto;
}

.mat-raised-button:focus {
  background-color: blue;
  color: yellow;
}

.mat-raised-button[disabled] {
  color: #000000;
  background-color: #cccccc;
}

button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-button-disabled {
  color: #000000;
  background-color: #cccccc;
}

/* mat-tab */
.mat-tab-label{
  background: #f5f5f5d8 ;
  color: black;
  font-weight:bold;
}

.mat-tab-label-active{
  background-color: #c9c9c9 ;
  opacity: 1 !important;
}

.ag-theme-balham .ag-header-cell-text {
  font-size: 16px;
}

.ag-theme-balham .ag-cell-value {
  font-size: 18px;
}

a {
  font-family: Arial !important;
}

a:focus {
  box-shadow: 0 0 0 .2rem #fff,
  0 0 0 .35rem #069 !important;
}

body {
  display: block;
  margin: 8px;
}

h2{
  margin: 0px;
}

.mainPageTitle {
  border-width: 0px;      
  font-size:22px;  
  font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.mainPageWelcomeMessage {
  border-width: 1px;
  text-align: left;
  font-size:32px;
  display: flex;
  font-weight: 700;
  font-family: Arial Bold, Arial, sans-serif;
}

.mainPageOptionMsg{
  text-align: left;
  display: flex;
  font-size:24px;
}

.mainPageIconTitle {
  display: block; 
  line-height: 50px; 
  font-size: 18px;
}

.mainPageIcon {
  height:98px;
  width:98px;
}

.mainPageIconTD {  
  border-style: solid;
  border-width: 1px;
  background-color: #C5EEFA;
  width: 400px;
  height: 331px;
  border-radius: 0px;
}

.mainPageIconTDGrey {
  border-style: solid;
  border-width: 1px;
  background-color: #CCCCCC;
  width: 400px;
  height: 321px;
  border-radius: 0px;
}
.onboardingStepMainTitle {
  border-width: 0px;
  padding-left:10px;
  font-size:32px;        
  display: flex;
  font-family: Arial-BoldMT, 'Arial Bold', Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.onboardingStepSubTitle {
  border-width: 0px;
  padding-left:10px;
  font-size:22px;  
  font-family: Arial-BoldMT, 'Arial Bold', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.btnStyleGrey {
  background-color: #CCCCCC;
  color:black;
  border-radius: 8px;
  width:auto;
  height:auto;
}

.btnStyle {
  background-color: #00B2E3;
  color:black;
  border-radius: 8px;
  width:auto;
  height:auto;
}

label {
  font-family: ArialMT, Arial, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            color: rgb(0, 0, 0);
            line-height: 15px;
}

fieldset {
  border: solid; 
  border-width: thin; 
  border-color: #d3cdcd; 
  padding: 10px;
}

legend {
  width:auto; 
  border: 0px; margin: 0px; 
  font-size: 14px; 
  font-weight: bold;
}

.important_instruction {
  font-size: 14px;
  line-height: 1.2em;
  font-weight: bold;
  font-family: 'Arial-BoldMT', 'Arial Bold', 'Arial', sans-serif;
}

.mat-error {
  color: #D60265;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgb(0, 0, 0, .55);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgb(0, 0, 0, .55);
}

mat-header-cell, mat-header-row, .mat-header-cell-def{
  min-height:20px !important;
  font-family:Verdana,sans-serif;
  font-weight:bold !important;
  font-size:13px !important;
  color: #1a084c !important;
  margin-bottom:5px;
  margin-top:5px;
  top: 0;
  position: sticky;
  z-index: 1 !important;
  background-color: #efeeee !important;
}

.mat-table {
  overflow: auto;
  max-height: 350px;
}

[class*=" mat-column-"] {
  padding-left: 5px;
  padding-right: 5px; 
  border-right: 1px solid #e0e0e0;
  align-self: stretch;
}

.mat-row {
  width: 100%;
  min-height: 30px;
}

.mat-row:nth-child(odd){
  background-color:rgb(244, 248, 248);
}

.mat-row:focus {
  background-color: rgb(174, 174, 202) !important;
}

.selectedRow {
  background-color: rgba(199, 202, 202, 0.589)!important;
}

.mat-cell {
  font-size: 14px;
  justify-content:left;
}

a.mat-cell-link {
  font-size: 14px;
  color:blue;
  text-decoration: none;
}

a.mat-cell-link:hover {
  text-decoration: underline !important;
  cursor: pointer;
}


.mat-expansion-panel-header {
  background-color: #c5eefa !important;  
  border-style: solid;
  border-width: 0.5px;
}

.mat-expansion-panel-header:hover, .mat-expansion-panel-header:focus {
  background-color: #00b1e3 !important;  
}

